var menu = null;
var menu_open = false;
var search_open = false;
var switching = false;
var menu_init = false;


!function(e){var a=e,r="undefined"!=typeof window&&window,f={frameLoaded:0,frameTry:0,frameTime:0,frameDetect:null,frameSrc:null,frameCallBack:null,frameThis:null,frameNavigator:window.navigator.userAgent,frameDelay:0,frameDataSrc:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC"};a.fn.backDetect=function(e,r){f.frameThis=this,f.frameCallBack=e,null!==r&&(f.frameDelay=r),f.frameNavigator.indexOf("MSIE ")>-1||f.frameNavigator.indexOf("Trident")>-1?setTimeout(function(){a('<iframe src="'+f.frameDataSrc+'?loading" style="display:none;" id="backDetectFrame" onload="jQuery.fn.frameInit();""></iframe>').appendTo(f.frameThis)},f.frameDelay):setTimeout(function(){a("<iframe src='about:blank?loading' style='display:none;' id='backDetectFrame' onload='jQuery.fn.frameInit();'></iframe>").appendTo(f.frameThis)},f.frameDelay)},a.fn.frameInit=function(){f.frameDetect=document.getElementById("backDetectFrame"),f.frameLoaded>1&&2===f.frameLoaded&&(f.frameCallBack.call(this),r.history.go(-1)),f.frameLoaded+=1,1===f.frameLoaded&&(f.frameTime=setTimeout(function(){e.fn.setupFrames()},500))},a.fn.setupFrames=function(){clearTimeout(f.frameTime),f.frameSrc=f.frameDetect.src,1===f.frameLoaded&&-1===f.frameSrc.indexOf("historyLoaded")&&(f.frameNavigator.indexOf("MSIE ")>-1||f.frameNavigator.indexOf("Trident")>-1?f.frameDetect.src=f.frameDataSrc+"?historyLoaded":f.frameDetect.src="about:blank?historyLoaded")}}(jQuery);
String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

$ = jQuery;

$(document).ready(function () {

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    $( ".single_variation_wrap" ).on( "show_variation", function ( event, variation ) {
        // Fired when the user selects all the required dropdowns / attributes
        // and a final variation is selected / shown
        var slideHtml = '<div class="pane" aria-hidden="true" tabindex="-1"><a class="slick-slider-anchor" href="{{ image }}" tabindex="-1"><img src="{{ image }}" class="product_image"></a></div>'
        slideHtml = slideHtml.replaceAll("{{ image }}", variation.image.full_src);
        console.log(slideHtml);
        $('.main-slick-slider').slick("unslick");
        $('.main-slick-slider').append(slideHtml);
        $('.main-slick-slider').slick({
            autoplay: false,
            arrows: false,
            dots: false,
        });
        $(".main-slick-slider").slick('slickGoTo', $('.main-slick-slider .slick-track').children().length - 1);

        console.log(variation);
    } );

    $('.filter-sidebar .widgettitle').on('click', function() {
        $(this).parent().toggleClass('active');
    })

    $('#shop-sidebar h1').on('click', function() {
        $('#shop-sidebar').toggleClass('open');
    })

    $('.slick-slider-anchor').magnificPopup({ deletegate: 'a', type:'image', gallery: { enabled: true}});



    // //Homepage Carousel
    // window.onYouTubeIframeAPIReady = function() {

    //     function videoSlider(videoSlide) {

    //         $(videoSlide).slick({
    //             autoplay: false,
    //             nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-angle-right-circle"></span></button>',
    //             prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-angle-left-circle"></span></button>',
    //             responsive: [{
    //                 breakpoint: 767,
    //                 settings: {
    //                     // arrows: false
    //                 }
    //             }]
    //         });

    //         function initiateVideos() {

    //             slideCount = jQuery('#slider .slick-slide:not(.slick-cloned)').length;
    //             function construct() {

    //                 currentVideo = '';
    //                 player = '';

    //                 currentOverlay = '.slick-active .video-overlay';

    //                 if(document.querySelector('.slick-active .slide-video')) {
    //                     //Gets iframe video from current slide
    //                     currentVideo = document.querySelector('.slick-active .slide-video');

    //                     //Gets data attributes from video
    //                     var videoID = $(currentVideo).data('videoid');
    //                     var videoWidth = $(currentVideo).data('videowidth');
    //                     var options = {
    //                         id: videoID,
    //                         width: videoWidth
    //                     };

    //                     player = new YT.Player(currentVideo, {
    //                         height: '1080',
    //                         width: '1920',
    //                         videoId: videoID,
    //                         playerVars: { 'modestbranding': 1, 'rel': 0, 'autoplay': 0, 'showinfo' : 0, controls: 0 },
    //                         events: {
    //                             'onReady': onPlayerReady,
    //                             'onStateChange': onPlayerStateChange
    //                         }
    //                     });

    //                     function onPlayerReady(event) {
    //                         event.target.playVideo();
    //                         event.target.mute();                        
    //                     }

    //                     function onPlayerStateChange(event) {
    //                         if(event.data == YT.PlayerState.PLAYING) {
    //                           document.querySelector(currentOverlay).classList.add('video-overlay--active');
    //                         }
    //                         if (event.data == YT.PlayerState.ENDED) {
    //                             player.stopVideo();
    //                             if(typeof slideCount !== 'undefined' && slideCount > 1) {
    //                                 jQuery(videoSlide).slick('slickNext');
    //                             }else {
    //                                 player.playVideo();
    //                             }
    //                         }
    //                     }
    //                     slideChangeTimeout = '';
    //                 }else {
    //                     slideChangeTimeout = setTimeout(function(){
    //                         if(typeof slideCount !== 'undefined' && slideCount > 1) {
    //                             jQuery(videoSlide).slick('slickNext');
    //                         }   
    //                     }, 12000);  
    //                 }
    //             }

    //             jQuery(videoSlide).on('afterChange', function(){
    //                 //Clears Settimeout from slide without video
    //                 clearTimeout(slideChangeTimeout);            

    //                 //Destroys last used iframe player
    //                 if(player){
    //                     var element = document.querySelector('.video-overlay.video-overlay--active');
    //                     if(typeof(element) != 'undefined' && element != null){
    //                       element.classList.remove('video-overlay--active');
    //                     }             
    //                     player.destroy();
    //                 }   
    //                 construct();
    //             });

    //             construct();
    //         }

    //         initiateVideos();

    //     }

    //     if(document.querySelector('section#slider')){
    //         videoSlider('section#slider');
    //     }


    //     function initiateCategoryVideo(categoryVideo) {

    //         videoOverlay = '.category-video-overlay';

    //         //Gets iframe video
    //         categoryVideo = document.querySelector(categoryVideo);

    //         //Gets data attributes from video
    //         var videoID = $(categoryVideo).data('videoid');

    //         player = new YT.Player(categoryVideo, {
    //             height: '1080',
    //             width: '1920',
    //             videoId: videoID,
    //             playerVars: { 'modestbranding': 1, 'rel': 0, 'autoplay': 0, 'showinfo' : 0, controls: 0 },
    //             events: {
    //                 'onReady': onPlayerReady,
    //                 'onStateChange': onPlayerStateChange
    //             }
    //         });

    //         function onPlayerReady(event) {
    //             event.target.playVideo();
    //             event.target.mute();                        
    //         }

    //         function onPlayerStateChange(event) {
    //             if(event.data == YT.PlayerState.PLAYING) {
    //               document.querySelector(videoOverlay).classList.add('category-video-overlay--active');
    //             }
    //             if (event.data == YT.PlayerState.ENDED) {
    //                 player.stopVideo();
    //                 player.playVideo();
    //             }
    //         }
    //     }

    //     if(document.querySelector('.category-header__video .category-video')){
    //         initiateCategoryVideo('.category-header__video .category-video');
    //     }

    // };



    $('section#slider').slick({
        autoplay: false,
        autoplaySpeed: 4000,
        nextArrow: '<button type="button" class="slick-next"><span class="pe-7s-angle-right-circle"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="pe-7s-angle-left-circle"></span></button>',
        responsive: [{
            breakpoint: 767,
            settings: {}
        }]
    });








    var inputs = $('.input-type-file input');
    inputs.each(function(index) {
        var label = $(this).parent();
        $(this).on('change', function(e) {
            var filename = '';
            console.log($(this).prop("files"));
            if($(this).prop("files")) {
                fileName = $(this).prop("files")[0]['name'];
            }
            $(label).find('span').text(fileName);
        });
    });

    // $('.main-slick-slider').on('init', function() {
    //     $('.main-slick-slider .slick-current img').elevateZoom({
    //         zoomType : "Window",
    //         easing: "true",
    //         zoomWindowFadeIn: 700,
    //         borderSize: 2,
    //     });
    // });

    $('.product_image').on('click', function(){

        var stateObj = { foo: "bar" };
        window.history.pushState(stateObj, "test", window.location.pathname);

        window.onpopstate = function(){
            $('.mfp-container').trigger('click');
        }
    });

     $('.zoom-icon').on('click', function(){

        var stateObj = { foo: "bar" };
        window.history.pushState(stateObj, "test", window.location.pathname);

        window.onpopstate = function(){
            $('.mfp-container').trigger('click');
        }
    });



    $('.main-slick-slider').on('afterChange', function(event, slick, currentSlide) {
       $('.zoomContainer').remove();
    });

    $('.main-slick-slider').slick({
        autoplay: false,
        arrows: false,
        dots: false,
    });


    $(".thumb-slick-slider .pane").click(function(e){
        e.preventDefault();
        slideIndex = $(this).data('index');
        $('.main-slick-slider').slick('slickGoTo', slideIndex);
        $('.main-slider-wrapper .slick-slider-anchor').first().attr("href", $('.main-slick-slider .slick-slide[data-slick-index='+slideIndex+'] a').attr('href'));

    });

    $('.thumb-slick-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        infinite: false,
    });


    $('section#featured-brands .brands').slick({
        slidesToShow: 6,
        slidesToScroll: 3,
        prevArrow: $('#brands-prev'),
        nextArrow: $('#brands-next'),
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    arrows: false
                }
            }
        ]
    });

    $('section#featured-jewellery .jewellery').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: $('#jewellery-prev'),
        nextArrow: $('#jewellery-next'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    });
    

    $('section#upsells .upsells').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: $('#upsells-prev'),
        nextArrow: $('#upsells-next'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    //arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    //arrows: false
                }
            }
        ]
    });

    $('section#cross-sells .cross-sells').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: $('#cross-sells-prev'),
        nextArrow: $('#cross-sells-next'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    //arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    //arrows: false
                }
            }
        ]
    });

    
    menu = $('#menu-main-menu').slicknav({
        appendTo: $('.mobile-navigation'),
        label: '',
        afterClose: function () {
            if (search_open == false && switching == true) {
                $('#mobile-search').stop().slideDown('fast');
                switching = false;
                search_open = true;
            }
        },
        beforeOpen: function () {
            if (menu_init == false) {
                menu_init = true;
                $('.account-links').clone().appendTo(menu);
            }
        }
    });
});


$('#search').on('click', function (e) {
    e.preventDefault();

    if (menu_open == true && search_open == false) {
        switching = true;
        menu_open = false;
        menu.slicknav('close');
    } else {
        $('#mobile-search').stop().slideToggle('fast');
        search_open = search_open == true ? false : true;
    }
});


$('#menu').on('click', function (e) {
    e.preventDefault();

    if (search_open == true && menu_open == false) {
        $('#mobile-search').stop().slideUp('fast', function () {
            menu.slicknav('open');
            search_open = false;
        });
    } else {
        menu.slicknav('toggle');
    }

    menu_open = menu_open == true ? false : true;
});